@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;600;700&display=swap');

html,
		body,
		#root,
		.app,
		.content {
		  height: 100%;
		  width: 100%;
		  font-family: "Public Sans", sans-serif;
		}

		.app {
		  display: flex;
		  position: relative;
		}

		/* Remove blur effect over image when upload it */
		span.component-image-wrapper{
			filter:none!important;
		}

/* scrollbar styles */
::-webkit-scrollbar {
  /* width: 10px; */
  width: 6px;
}

/* scrollbar track styles */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* scrollbar handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* scrollbar handle on hover */
::-webkit-scrollbar-track:hover {
  background: #555;
}

/* :hover {
  svg {
    path {
      stroke: #CDD2D6;
    }  
  }
} */
